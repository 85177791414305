body,
html {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    width: 100%;
    overflow: auto !important;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'Oleo Script', system-ui;
}

a {
    color: inherit;
    text-decoration: none;
}
.lazyload-wrapper {
    display: inherit;
}
.Infrastructure h4 {
    font-size: 17px;
    text-align: center;
    font-family: 'Poppins';
    text-transform: uppercase;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 0px;
    font-weight: bold;
}

.Infrastructure img {
    object-fit: contain;
    background: #f1f1f1;
    height: 300px;
    object-position: center;
}

.highlights {
    background: #1c1b3b;
    color: #fff;
    min-height: 24px;
}

.highlights p {
    margin: 0;
    padding: 0;
    font-size: 13px;
    line-height: 24px;
}

.highlights marquee {
    margin: 0;
    padding: 0;
    display: flex;
}

.para {
    background: #f7f7f7;
    line-height: 58px;
    padding: 119px;
    border-radius: 10px;
}

.accordion-button {
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    margin-top: 0px;
}

.accordion-button {
    background-color: #e7f1ff;
}

.accordion-button:not(.collapsed) {
    color: #ffffff;
    background: #0374a0 linear-gradient(2deg, #0f698c 0%, #2993bd 50%, #1197cc 100%) repeat scroll 0 0;
}

.achievementimg img {
    height: 250px;
    width: 100%;
    object-fit: contain;
    border: 6px double #b4dcec;
    border-radius: 5px;
    background: #fff;
    margin-bottom: 15px;
    padding-left: 0px;
}

.app_new {
    width: 100px;
}

.view-more {
    cursor: pointer;
}

.navbar-dark .navbar-toggler-icon {
    outline: none;
}

.navbar-dark .navbar-toggler-icon:focus {
    outline: none;
}

.navbar-dark .navbar-nav .nav-link {
    color: #085dad;
    font-weight: bold;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: #5f9019;
}




.goal-box {
    box-shadow: 1px 1px 8px 0px #b7b7b7;
    background: #efefef;
    padding: 15px 5px;
}

.goal-box h4 {
    text-align: center;
    font-size: 19px;
    margin-bottom: 5px;
    text-transform: capitalize;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-family: 'Poppins', sans-serif;
}

.goal-box h5 {
    text-align: center;
    font-size: small;
    color: red;
    font-family: 'Poppins', sans-serif;
}

.Goal img {
    width: 70px;
    height: 70px;
    margin-bottom: 5px;
}





.logo-sec-contact ul {
    margin: 0px;
    margin-bottom: 14px;
}

.logo-sec-contact ul li {
    color: #324759;
    display: block;
    text-align: center;
}

.logo-sec-contact ul li a {
    color: #324759;
    text-decoration: none;
}

.logo-sec-contact ul li a:hover {
    color: #000000;
}

.logo-sec-contact ul li i {
    color: #004d9b;
}

.logo-sec-contact ul:hover {
    color: #fff;
}

.menu-sec ul li i {
    color: #ffffff;
    height: 31px;
    width: 31px;
    vertical-align: middle;
    border-radius: 100%;
    display: inline-block;
    padding: 0px;
    font-size: 16px;
    margin-right: 5px;
}

.menu-sec .navbar {
    margin: auto;
}

.menu-sec .navbar-collapse ul {
    text-align: center;
}

.menu-sec li:nth-child(1) i {
    background: #51b574;
}

.menu-sec li:nth-child(2) i {
    background: #0092fa;
}

.menu-sec li:nth-child(3) i {
    background: #b7475c;
}

.menu-sec li:nth-child(4) i {
    background: #bd9559;
}

.menu-sec li:nth-child(5) i {
    background: #b62cbf;
}

.menu-sec li:nth-child(6) i {
    background: #d54762;
}

.menu-sec li:nth-child(7) i {
    background: #e7a138;
}

.menu-sec li:nth-child(8) i {
    background: #000;
}

.menu-sec li:nth-child(9) i {
    background: #f44335;
}

.menu-sec .navbar li a {
    color: #fff !important;
    font-weight: normal !important;
    font-size: 15px;
    line-height: 25px;
    padding: 0;
}

ul.navbar-nav {
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
    flex: unset;
}

ul.navbar-nav li {
    margin: 0 4px;
}

.blinker {
    background: #000;
    padding: 6px 13px !important;
    border-radius: 5px;
    box-shadow: 0px 0px 1px 0px #ddd;
    animation: 2s blinker infinite;
    position: relative;
    z-index: 99;
}

.blinker::after,
.arrow::after {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 50%;
    position: absolute;

}

.arrow img {
    width: 45px !important;
    height: auto !important;
}

.message img {
    width: 660px;
    height: 320px;
}

.arrow {

    animation: 2s shade infinite;
    border-radius: 50%
}

.navbar-expand-lg .navbar-nav .dropdown-menu li a {
    color: #000 !important;
    text-align: left;
    padding: 5px 10px;
    border-bottom: 1px solid #c7852254;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    padding: 0;
    border-radius: 0;
    margin-top: 9px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu li {
    padding: 0;
    margin: 0;
}

.navbar-expand-lg .navbar-nav .dropdown-menu li a:hover {
    background: #1c1b3b;
    color: #fff !important;
}

.navbar-expand-lg .navbar-nav .dropdown-menu li:last-child a {
    border-bottom: 0px solid #ccc;
}

@keyframes shade {
    0% {
        box-shadow: 0 0 0 0px #e7a138, 0 0 0 0px #e7a138d1;
    }

    100% {
        box-shadow: 0 0 0 15px rgba(0, 210, 255, 0), 0 0 0 30px rgba(0, 210, 255, 0);
    }
}

@keyframes blinker {
    0% {
        background: #e7a138;
        box-shadow: 0 0 0 0px #e7a138, 0 0 0 0px #e7a138d1;
    }

    50% {
        transform: scale(0.95);
    }

    100% {
        background: #ad6e11;
        box-shadow: 0 0 0 15px rgba(0, 210, 255, 0), 0 0 0 30px rgba(0, 210, 255, 0);
    }
}

header {
    background: #ffffff;
    padding-top: 2px;
}

.menu-sec {
    background: #1c1b3b;
    padding: 0px 15px;
    color: #fff;
}

.navbar-dark .navbar-toggler-icon:focus {
    outline: none;
}

.navbar-dark .navbar-toggler-icon {
    outline: none;
}

ul.list-block li:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: radial-gradient(white, #ffffffbf);
}

.list_blk {
    position: relative;
    font-weight: 400;
    font-family: 'Oleo Script', system-ui;
}

button.navbar-toggler {
    display: none;
}

.gallery {
    padding: 60px 0;
    background: url('https://webapi.entab.info/api/image/SHSJ/public/Images/texture.jpg');
    position: relative;
}

.gallery:before {
    content: "";
    background: #ffffffd1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}






.videogallery img {
    margin-bottom: 20px;
    height: 230px !important;
    width: 100% !important;
    object-fit: contain;
    margin: 0px !important;
    object-position: center;
    background: #dcdff3;

}


.video-gallery {
    position: relative;
}

.play-icon {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    color: white;
    z-index: 10;
}

.videogallery img {
    width: 100%;
    height: auto;
}










.videogallery {
    position: relative;
    overflow: hidden;
}

.videogallery img {
    width: 100%;
    height: auto;
}

.videogallery .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 23%);
    z-index: 1;
}

.videogallery .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 30px;
    z-index: 2;
}

.videogallery:hover .overlay {
    background-color: rgba(0, 0, 0, 0.3);
    /* Lighter overlay on hover */
}










.header-contact i {
    margin-right: 5px;
    color: #1c1b3b;
}

.header-contact p {
    margin-bottom: 2px;
    font-size: 15px;
}

.header-contact p i {
    font-size: 19px;
}

.social-media ul {
    list-style: none;
    padding: 0;
    display: flex;
    margin-bottom: 0;
    margin-top: 8px;
}

.social-media ul li {
    margin-right: 15px;
    border-radius: 50%;
}

.social-media {
    margin-left: 30px;
}

.d-flex.space-between {
    align-items: center;
    justify-content: space-between;
}

.slider .demo {
    margin: auto;
    width: 100%;
    position: relative;
    box-shadow: 0px 0px 5px 0px #000;
    z-index: 9;
}

.alumni-register span {
    display: block;
}

.alumni-register {
    position: fixed;
    right: 0%;
    transform: translate(-0%, -50%);
    top: 50%;
    z-index: 999;
    border-radius: 10px 0px 0 10px;
    border: 2px solid #7c7c7c;
    transition: 0.5s;
    cursor: pointer;
}

.alumni-register img {
    width: 61px;
    margin-right: 6px;
    position: absolute;
    left: 0%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #7c7c7c;
    border-radius: 5px;
    box-shadow: 0px 0px 14px 0px #898989;
}

.alumni-register {
    background: #ffffffd1;
    padding: 4px 17px 4px 42px;
    border-right: 0;
}

.alumni-register p {
    margin-bottom: 0;
    font-family: 'Oleo Script', system-ui;
    font-size: 25px;
    text-align: center;
    margin-bottom: 0px;
}

.alumni-register p:nth-child(2) {
    font-size: 18px;
    color: #d9b840;
    margin-bottom: 0;
}

.alumni-register:hover {
    background: #1c1b3b;
    color: #fff;
}

.slider {
    width: 100%;
    position: relative;
    background-size: 100% 100%;
    overflow: hidden;
}

ul#content-slider img {
    width: 100%;
    max-height: 100%;
    display: block;
    position: relative;
}

ul.list-block {
    display: flex;
}

ul.list-block li {
    margin: 0 25px;
    position: relative;
    text-align: center;
    padding: 20px 10px;
    border-radius: 6px;
    box-shadow: 0px 0px 9px 0px #959595;
    font-size: 23px;
    width: 210px;
    background: url('https://webapi.entab.info/api/image/SHSJ/public/Images/texture.jpg');
    border-bottom: 5px solid #0d0da1;
    overflow: hidden;
}

ul.list-block li img {
    display: block;
    margin: auto;
    width: 83px;
    margin-bottom: 9px;
}

.mobileonline .fix-list {
    display: none;
}

.demo .item {
    position: relative;
    z-index: 9;
    overflow: hidden;
}

.about-img img {
    width: 56%;
    margin: 0px auto 40px;
    display: block;
    border-radius: 5px;
    position: relative;
    box-shadow: 0px 0px 5px 0px #707070;
}

img.kgschool {
    position: absolute;
    right: 50px;
    bottom: -80px;
    width: 361px;
    z-index: 99;
    margin: 0;
    box-shadow: 0px 0px 5px 0px #707070;
}

.about-img {
    position: relative;
}

.about-img:before {
    content: "";
    width: 60%;
    height: 100%;
    position: absolute;
    border: 5px solid #d9b840;
    right: 108px;
    bottom: -50px;
}

.about {
    margin: 120px 0;
    padding-right: 90px;
}

button {
    font-size: 18px;
    padding: 5px 30px;
    border-radius: 5px;
    border: 0px;
    background: #d9b840;
    color: #fff;
    text-transform: uppercase;
    margin-top: 20px;
}

p span {
    color: #d9b840;
}

.about p {
    line-height: 30px;
}

p {
    font-size: 16px;
}

ul.list-block {
    display: flex;
}

ul.list-block {
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-top: -80px;
    z-index: 99;
    position: relative;
}

ul.list-block li:nth-child(1) {
    border-bottom: 5px solid #c92635;
}

ul.list-block li:nth-child(2) {
    border-bottom: 5px solid #c9ad26;
}

ul.list-block li:nth-child(3) {
    border-bottom: 5px solid #2633c9;
}

ul.list-block li:nth-child(4) {
    border-bottom: 5px solid #29c926;
}

ul.list-block li:nth-child(5) {
    border-bottom: 5px solid #26c1c9;
}

.message-content {
    padding: 27px 50px;
    background: #1c1b3b;
    height: 100%;
    box-shadow: 0px 0px 5px 0px #000;
    color: #fff;
}

.message:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #0000006e;
    position: absolute;
    top: 0;
    left: 0;
}

.message-content:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    left: -40px;
    top: 0;
    /* background: red; */
    border-top: 160px solid #0000;
    border-bottom: 160px solid #0000;
    border-right: 40px solid #1c1b3b;
}

.shape-arrow::before {
    left: unset;
    right: -40px;
    z-index: 9;
    transform: rotatey(180deg);
}

.message-content p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8;
    overflow: hidden;
}

.padding-0 {
    padding: 0;
    position: relative;
}

.message {
    padding: 90px 0;
    background: url('https://webapi.entab.info/api/image/SHSJ/public/Images/slide2.jpg') no-repeat;
    background-size: 100% 100%;
    position: relative;
    background-attachment: fixed;
}

.message-content h3 {
    color: #e3c351;
    margin-bottom: 18px;
    font-size: 38px;
}

.arrow {
    position: absolute;
    transform: translate(-50%, -50%) rotate(180deg);
    right: -47px;
    top: 15%;
    z-index: 9;
}

.message-content h3 {
    font-size: 34px;
    color: #edd272;
}

.galtitle {
    position: relative;
}

.gallery-blk {
    display: flex;
    flex-wrap: wrap;
}

.gallery-blk .gallery-img {
    width: calc(33% - 20px);
    border: 5px solid #ffffff;
    position: relative;
    box-shadow: 0px 0px 10px #545454;
    margin: 10px;
    border-radius: 5px;
}

.gallery-blk .gallery-img img {
    width: 100%;
    height: 300px;
    object-fit: contain;
    background: #efefef;
    object-position: top;
}

.gallery:before {
    content: "";
    background: #ffffffd1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.gallery-blk .gallery-img .title {
    position: absolute;
    background: #1c1b3ba8;
    width: 100%;
    z-index: 9;
    padding: 6px;
    text-align: center;
    bottom: 10px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    height: 37px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #fff;
}

.gallery-blk .gallery-img:hover .title {
    height: 100%;
    bottom: 0;
    font-size: 22px;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #fff;
    align-items: center;
    justify-content: center;
}

.news-event {
    background: linear-gradient(90deg, #e3c351 55%, #dbdbdb 40%);
    padding: 100px 0;
}

.news {
    margin-bottom: 50px;
}

.news,
.events {
    padding: 0 90px 0 40px;
}

.news-blk {
    background: #fff;
    padding: 0;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    width: 97%;
    box-shadow: 0px 0px 5px 0px #c3a53a;
    margin-bottom: 5px;
}

.news-blk .date {
    background: #1c1b3b;
    margin-left: 10px;
    padding: 20px 25px;
    border-radius: 10px 0px 19px 19px;
    color: #fff;
    margin-top: -10px;
    position: relative;
    margin-bottom: 25px;
}

.news-blk .content {
    padding: 20px;
}

.news-blk h3 span {
    display: block;
    font-size: 57%;
    text-transform: uppercase;
}

.news-blk .date:before {
    content: "";
    width: 15px;
    height: 10px;
    position: absolute;
    right: -11px;
    top: 0;
    background: #1c1b3b;
    border-radius: 0 5px 0 0;
}

.news-blk .date h3 {
    text-align: center;
    font-size: 36px;
    margin-bottom: 0;
}

.news-blk .content p,
.event-blk .content p {
    display: -webkit-box;
    margin-bottom: 0;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.event-image {
    width: 120px;
}

.event-image img {
    height: 100px;
    width: 100px;
    object-fit: contain;
    background: #f1f1f1;
}


.event-blk {
    display: flex !important;
    background: #fff;
    margin: 5px;
    box-shadow: 0px 0px 5px 0px #c3a53a;
    border-radius: 5px;
    overflow: hidden;
}

.event-blk .content {
    padding: 14px;
    width: 100%;
}

.event-blk .content p span {
    font-size: 76%;
}

.event-blk .content p {
    margin-bottom: 0;
}

.acheiver-blk {
    margin-top: 13px;
    background: #fff;
    display: flex;
    width: 98%;
    border-radius: 7px;
    position: relative;
    box-shadow: 0px 0px 5px 0px #b3b3b3;
    align-items: center;
    margin-left: 5px;
}

.acheiver-blk .acheiver-image img {
    width: 131px;
    border-radius: 10px;
    height: 125px;
    border: 5px solid #fff;
    object-fit: cover;
}

.acheiver-image {
    margin-right: 5px;
    width: 130px;
}

.acheiver-blk .content {
    padding: 13px 0 3px;
    text-align: center;
    text-align: left;
    width: 100%;
}

.acheiver-blk .percentage {
    right: 20px;
    position: absolute;
    top: -13px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Oleo Script', system-ui;
    color: #d9a900;
    background: #113e80;
    padding: 10px;
    /* text-shadow: 0px 3px 0px #838383; */
}

.evHUxX span i {
    height: 0;
    font-size: 44px;
    float: right;
}

.dijvDY p i.bi.bi-arrow-right-circle {
    font-size: 34px;
    height: 0;
    float: right;
}

.acheiver-blk .content h3 {
    margin-bottom: 0;
    overflow: hidden;
    font-size: 17px;
}

.acheiver-blk .content p {

    font-size: 12px;
}

.acheiver-blk img.balon-gif {
    width: 60px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.acheiver-main {
    margin-bottom: 58px;
}

.slick-prev {
    z-index: 9;
    left: 6px;
}

.slick-next {
    z-index: 9;
    left: 6px;
}

.crossIcon {
    position: fixed;
    right: 6px;
    top: 8px;
    font-size: 5rem;
    transform: rotate(45deg);
    background: #ffffff73;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    line-height: 3;
    z-index: 99;
    cursor: pointer;
}

.crossIcon:hover {
    background: #fff;
}

.videoloader {
    position: fixed;
    top: 0;
    z-index: 999;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 1;
    transition: 0.5s;
    background: #000;
}

.videoloader video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.logo-load {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.logo-load img {
    width: 300px;
    margin: auto;
    display: block;
}

.logo-load h1 {
    text-align: center;
    color: #fff;
    font-size: 60px;
    text-shadow: 0 0 5px black;
}

.videoloader.active {
    opacity: 0;
}

footer .header-contact {
    margin-bottom: 30px;
    padding: 0 30px;
}

footer .header-contact p {
    margin-bottom: 15px;
}

footer {
    padding: 40px 40px 10px;
    background: #1c1b3b;
    color: #fff;
}

.link_Button img {
    margin: 10px;
}

footer .social-media {
    margin: 0;
}

footer .social-media h3 {
    margin-bottom: 20px;
    margin-top: 28px;
}

.copyright {
    background: #ffffff;
    color: #000;
    text-align: center;
    padding: 5px 0;
}

.copyright p {
    margin-bottom: 0;
    font-size: 13px;
}

.ftr-logo img {
    margin-bottom: 14px;
}

.ftr-logo {
    color: #fff;
}

footer .header-contact i {
    color: #e3c351;
}

.social-media ul {
    list-style: none;
    padding: 0;
    display: flex;
    margin-bottom: 0;
    margin-top: 8px;
}

.social-media ul li {
    margin-right: 15px;
    border-radius: 50%;
}

.social-media {
    margin-left: 30px;
}

.mobile-apps {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.mobile-apps>img.img-fluid {
    width: 40%;
}

.q-link {
    width: 100%;
}

.q-link ul li {
    margin-bottom: 11px;
}

.q-link ul {
    margin-top: 20px;
}

a:hover {
    color: inherit;
}

.popup.modal button {
    margin-top: 0;
}

.popup .carousel-control-prev-icon {
    background: url('https://webapi.entab.info/api/image/SHSJ/public/Images/lefticn.png') no-repeat !important;
}

.popup .carousel-control-next-icon {
    background: url('https://webapi.entab.info/api/image/SHSJ/public/Images/righticn.png') no-repeat !important;
}

.popup .carousel-control-next,
.popup .carousel-control-prev {
    width: auto;
}

.popup .carousel-control-next {
    right: -15px;
}

.popup .modal-dialog {
    min-width: 800px;
    transform: translateY(-100px);
}

.carousel-item .modal-header {
    background: #0a4685;
    padding: 13px 0 0
}

.popup .modal-dialog img {
    width: 100%;
    object-fit: contain;
}

.carousel-item .modal-body h1 {
    padding-bottom: 0;
    text-align: center;
    font-size: 17px;
    color: #343131;
    overflow: hidden;
    font-weight: 600;
    text-transform: capitalize;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 10px;
    line-height: 28px;
}

.carousel-item .modal-dialog .modal-header .btn-close {
    color: #fff;
    padding-right: 31px
}

.carousel-item .modalcard .card-body {
    padding: 10px
}

.carousel-item .modalcard .card-body p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 35px;
    text-align: center;
    padding-top: 10px;
    line-height: 26px;
}

.carousel-item .modalcard {
    border: none
}

.carousel-item .modal-body {
    padding: 0px 2rem;
}

.popup .modal-content {
    padding: 0 0 10px
}

.carousel-item button.btn-close:focus {
    box-shadow: none
}

.carousel-item a.btn.btn-primary.btn-sm {
    background: #1c1b3b;
    border: #1c1b3b;
    margin: 0 10px
}

.carousel-item a.btn.btn-primary.btn-sm:focus {
    box-shadow: none;
    border: 1px solid #182b3c;
}

.carousel-item button.btn-close {
    position: absolute;
    z-index: 999;
    background-color: #1c1b3b;
    right: 0;
}

.carousel-item .btn-close {
    background: url('https://webapi.entab.info/api/image/SHSJ/public/Images/closeicn.png') no-repeat;
    opacity: 1;
    padding: 10px;
    background-position: center;
    border-radius: 0;
}

.modal-bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.innerslide {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background: linear-gradient(360deg, #1c1b3b8f 100%, #1c1b3b91 100%, #3333338c 100%), url('https://webapi.entab.info/api/image/SHSJ/public/Images/innersld.jpg') no-repeat center center;
    background-size: cover;
    height: 255px;
    padding-bottom: 50px;
}

.innersec {
    min-height: 400px;
    padding: 100px 10px;
}

.innersec p {
    line-height: 34px;
    font-size: 16px;
    font-family: "Inter", sans-serif;
    color: #4e4e4e;
}

.innersec h1 {
    font-size: 35px;
    position: relative;
    padding-left: 70px;
    background: -webkit-linear-gradient(#c4a956, #d33633);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 45px;
    padding-bottom: 20px;
}

.innersec h1:after {
    position: absolute;
    content: ' ';
    width: 60px;
    height: 3px;
    background: linear-gradient(45deg, #ae6f11, #ffc297);
    top: 20px;
    bottom: 0;
    left: 0;
}

.aboutinnerimg {
    padding: 4px;
    outline: 2px solid #fff8f8;
    outline-offset: -15px;
    border-radius: 11px;
}

.breadcrumb li:before {
    position: absolute;
    content: ' \F138 ';
    width: 100%;
    height: 100%;
    font-family: bootstrap-icons;
    left: -15px;
}

.breadcrumb li:first-child:before {
    content: "\f404";
    background: #b78c0b;
    width: 30px;
    border-radius: 20px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -25px;
    top: -3px;
}

.breadcrumb li a {
    color: #ffd451;
    position: relative;
}

.breadcrumb li {
    padding: 0px 15px 0px 15px;
    color: #fff;
    position: relative;
    margin-left: 7px;
    font-size: 14px;
}

ul.breadcrumb {
    margin-bottom: 0;
}

.quotes {
    padding: 15px 30px;
    background: linear-gradient(45deg, #dfdeff, transparent);
    border-radius: 1px;
    outline-offset: 5px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.motobox {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.motoboxfst {
    width: 48%;
    background: #f1f1f18a;
    padding: 30px;
    margin: 15px 21px 15px 0px;
    border-radius: 4px;
    border: 1px solid #cccccc36;
}

.motoboxfst h4 {
    color: #b78c0b;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 25px;
}

.motoboxfst h4:after {
    position: absolute;
    bottom: 0px;
    border-bottom: 2px dashed #b78c0b;
    content: ' ';
    width: 22%;
    left: 0;
}

ul.mainlist li {
    line-height: 30px;
    font-size: 16px;
    list-style: none;
    position: relative;
    font-family: "Inter", sans-serif;
    color: #4e4e4e;
    margin-bottom: 10px;
}

ul.mainlist li:before {
    content: "\2726";
    font-size: 1.6em;
    color: #b78c0b;
    position: absolute;
    left: -30px;
    top: 0;
}

.msgbox {
    width: 40%;
    float: right;
    margin-left: 30px;
    margin-bottom: 20px;
    position: relative;
}

.msgbox:before {
    position: absolute;
    top: -15px;
    left: -15px;
    content: "";
    width: 100%;
    height: 100%;
    background: #9ebdcd47;
}

.msgbox img {
    border: 1px solid #bdbdbd;
    padding: 4px;
    position: relative;
}

p.name {
    font-size: 16px;
    font-weight: bold;
}

p.name span {
    font-weight: 400;
    color: #ff3633;
    display: block;
}

.teacherimg {
    float: right;
    width: 43%;
    margin-left: 20px;
    margin-bottom: 20px;
}

.teacherimg img {
    border: 2px solid #1c1b3b;
    padding: 7px;
    border-radius: 4px;
    box-shadow: 0px 1px 1px 1px #ccc;
}

.maintable thead th {
    border: 1px solid #ccc;
    background: #1c1b3b;
    color: #fff;
}

.maintable tbody td {
    border: 1px solid #ccc;
}

.maintable tbody tr:nth-child(odd) {
    background: #f1f1f185;
}

.maintable tbody tr:nth-child(even) {
    background: #dfdeff;
}

.tablebg {
    background: #f1f1f18a;
    padding: 20px 10px;
    border: 1px solid #cccccc36;
}

.tablebg .maintable tbody tr:nth-child(odd) {
    background: #fff;
}

.fixed_header {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.fixed_header tbody {
    display: block;
    width: 100%;
    overflow: auto;
    height: 336px;
}

.fixed_header thead tr {
    display: block;
}

.fixed_header thead {
    background: black;
    color: #fff;
}

.fixed_header th,
.fixed_header td {
    padding: 5px;
    text-align: left;
}

.tablescrollbox {
    margin-top: 30px;
    margin-bottom: 25px;
}

a.click {
    color: #ff3633;
    font-weight: bold;
}

.innersec h6 {
    font-size: 19px;
    line-height: 30px;
    color: #9e790c;
    margin-bottom: 10px;
    margin-top: 20px;
    font-family: 'Inter';
}

.term {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 35px;
    margin-top: 25px;
}

.termbox p {
    margin-bottom: 0;
    font-weight: bold;
    color: #000;
}

.termbox {
    width: 30%;
    text-align: center;
    background: #deddfff5;
    margin: 0px 10px;
    padding: 10px;
    outline: 1px solid #9d9bcb;
    outline-offset: -6px;
    border: 1px double #9d9bcb;
}

.container-fluid.conductbox {
    padding: 0px 170px;
}

.bg1 {
    background: #f1f1f161;
    padding: 30px 30px;
}

.bg2 {
    background: #dfdeff4f;
    padding: 30px 15px 30px 45px;
}

.bg3 {
    background: #fffbeec7;
    padding: 30px 15px 30px 45px;
}

.contactusbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.contactusdesc {
    display: flex;
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
}

.contactusicn i {
    color: #fff;
    font-size: 20px;
}

.contactusicn {
    padding-right: 16px;
}

.contactusdiv {
    width: calc(35% - 30px);
    padding: 40px 30px;
    border-radius: 20px;
    margin: 0px 5px 20px 0px;
}

.fstcontact {
    background: #b51b41;
}

.scdcontact {
    background: #362f35;
}

.thrdcontact {
    background: #f6a440;
}

.thrdcontact .contactusdesc {
    flex-wrap: wrap;
}

.thrdcontact .contactusdesc>div {
    display: flex;
    margin-bottom: 5px;
    padding-right: 25px;
}

.contactusimg h4 {
    font-family: 'Inter';
    margin-top: 25px;
    margin-bottom: 20px;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
}

.tcblock {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.tc {
    width: 49%;
    margin-bottom: 30px;
}

.tcblock iframe {
    width: 49%;
}

.tc label {
    color: #000;
    width: 100%;
    margin-bottom: 0;
}

.tc label p {
    margin-bottom: 0px;
}

.tc .form-group {
    margin-bottom: 20px;
}

.form-control:focus {
    box-shadow: none;
    border: 1px solid #ccc;
}

a.viewdetailsbtn {
    color: #fff;
    font-size: 14px;
}

.viewdetailsbtn {
    padding: 10px 15px;
    text-decoration: none;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 20px;
    border: none;
    background: linear-gradient(45deg, #00082e, #e3a435);
    transition: all 0.2s ease-in;
}

.viewdetailsbtn:hover {
    background: linear-gradient(45deg, #00082e, #00082e);
}

.video-gallery {
    padding: 20px;
    box-shadow: 0px 0px 4px 0px #03030330;
    margin-bottom: 20px;
    border-radius: 4px;
    background: #a2abff47;
}

.videogallery {
    margin-bottom: 15px;
}

.video-gallery a {

    color: inherit;
    align-items: center;
}

/* .video-gallery a i {
   font-size: 40px;
   color: #ad0807;
   margin-right: 10px;
   display: flex;
} */
.video-gallery a h3 {
    position: static;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 24px;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 100;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-top: 0;
    padding-left: 0;
    background: transparent;
    -webkit-text-fill-color: unset;
    font-family: 'Poppins';
}

.video-gallery p {
    font-size: 12px;
    margin-bottom: 0;
}

.video-gallery a h3:after {
    position: static;
}

.video-gallery a img {
    width: 52px;
    margin-right: 10px;
}

.chooseyear {
    margin-bottom: 40px;
}

#chooseddlYear {
    border: unset;
    color: #000;
    height: 30px;
    padding: 0px 0px 0px 4px;
    background: unset;
    font-weight: bold;
    margin-bottom: 15px;
    letter-spacing: 1px;
    width: 6.5em;
    font-size: 15px;
    float: right;
    margin-top: 0;
    border-bottom: 1px solid grey;
}

.chooseyear select {
    margin: 0px;
}

select#chooseddlYear:focus-visible {
    outline: none;
}

.examresultboximg {
    width: 70px;
    margin-right: 15px;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #080e41;
}

.examresultbox {
    width: calc(26% - 30px);
    border: 4px solid #fff;
    border-radius: 5px;
    padding: 10px;
    background: #a2abff47;
    box-shadow: 0px 0px 3px 1px #b0b6df;
    margin: 10px 15px 6px 0px;
}

.examresultbox a {
    display: flex;
    align-items: center;
}

.examresultboxdesc h4 {
    font-size: 17px;
    margin-bottom: 0;
}

.examresult {
    display: flex;
    flex-wrap: wrap;
}

.erpbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.erpboxdesc {
    width: 72%;
}

.erpboximg {
    width: 26%;
}

.blinker i {
    background: transparent !important;
}

.erpboxdescpopup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.erppopup {
    width: 30%;
    margin: 10px;
}

.imgheading a {
    color: #20488a;
    text-align: center;
    width: 100%;
    display: block;
    font-size: 20px;
    padding: 10px 0;
}

.erppopup .modal-body h5 {
    color: #000;
    text-align: center;
    padding-top: 20px;
}

#myImg,
#myImg1,
#myImg2 {
    width: 100%;
    border: 5px solid rgb(191 185 188);
    background: #cbcaca;
    cursor: pointer;
    transition: .3s;
}

.calendar button {
    margin: 0;
    padding: 10px;
    background: #dfdeff;
}

.calendar h2 button {
    font-weight: normal !important;
    font-size: 15px;
    text-transform: capitalize;
    font-family: "Inter", sans-serif;
}

.calendar .accordion-item {
    margin-bottom: 5px;
}

.calendar .accordion-button:not(.collapsed) {
    background: #1c1b3b;
    color: #fff;
}

.calendar .accordion-button:focus {
    box-shadow: none;
}

.calendar .accordion-button:not(.collapsed)::after {
    filter: invert(1);
}

.newscountbox {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}

.news-block {
    width: 100%;
    position: relative;
    padding: 20px 10px 15px;
    background-color: #ffffffcf;
}

.newsCount .attachment {
    position: absolute;
    right: 0;
    bottom: 0px;
    background: #101130;
    padding: 8px 10px;
    border-radius: 3px 10px 3px 10px;
}

.newsCount .attachment a {
    color: #fff;
}

.newsCount {
    width: calc(51% - 35px);
    position: relative;
    padding: 15px;
    display: flex;
    background-color: #f2f4f7;
    justify-content: center;
    margin: 10px 10px;
}

.newsCount:before {
    position: absolute;
    left: 0;
    top: 0%;
    height: 0;
    width: 100%;
    content: "";
    background: linear-gradient(to right, #ff4200 0%, rgba(69, 74, 180, 1) 50%, #ffc600 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f14b59', endColorstr='#ffa200', GradientType=1);
    transition: all 500ms ease;
}

.newsCount:hover:before {
    height: 100%;
    top: 0;
}

.news-head h4 {
    padding: 10px 20px;
    font-size: 15px;
    background: #eea201;
    width: max-content;
    border-radius: 5px;
    color: #fff;
}

.news-head h3 {
    padding-left: 0;
    font-size: 16px;
    position: static;
    background: transparent;
    color: #000;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
    padding-bottom: 0;
    font-family: 'Poppins';
}

.news-head h3:after {
    position: static;
}

.row.tabs-dynamic {
    background: #f1f1f1;
    padding: 10px;
    margin: 0px 0px 40px 0px;
}

.row.tabs-dynamic p {
    margin-bottom: 0;
}

.row.tabs-dynamic select {
    width: 100%;
    border: 0px;
    background: transparent;
    padding: 8px 5px;
    border-bottom: 1px solid #b6b6b6;
}

.row.tabs-dynamic input {
    padding: 9px 5px;
    width: 100%;
    border: 0px;
    border-bottom: 1px solid #b6b6b6;
    background: transparent;
}

.count-val p {
    margin-bottom: 0px;
}

.row.tabs-dynamic p a {
    color: #333;
}

.years {
    display: none;
}

.showYear {
    display: flex;
}

.load-more .next {
    width: max-content;
    padding: 5px 10px;
    color: #fff;
    border-radius: 5px;
    margin: auto;
    margin-top: 20px;
    background: #1c1b3b;
}

.myDiv1 {
    display: none;
}

.selected_year {
    display: block;
}

.gallery-inner {
    background: #1c1b3b;
    margin-bottom: 30px;
    box-shadow: 7px 6px 12px -4px #ddd;
    /* position: relative; */
    overflow: hidden;
    transition: all 0.5s ease-in;
}

.gallery-blks a {
    color: inherit;
}

.gallery-blks img {
    width: auto;
    max-width: 100%;
    height: 90%;
    margin: auto;
    display: block;
    position: relative;
    box-shadow: 0px 0px 19px 0px #00000054;
    border: 7px solid #ffffff4f;
}

.gallery-title h3::after {
    position: static;
}

.gallery-inner:hover .gallery-content {
    bottom: 0;
}

.gallery-blks {
    height: 230px;
    object-fit: contain;
    position: relative;
    background: #ddd;
    overflow: hidden;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery-title p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    line-height: 28px !important;
    font-size: 16px !important;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
}

.gallery-date {
    display: flex;
}

.gallery-date h6 {
    color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 25px;
    background: #fc097c;
    padding: 5px 10px;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
    margin-top: 0;
}

.gall-num h5 {
    background: transparent;
    color: #000;
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 25px;
    padding: 5px 10px;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
}

.galleryCount:nth-child(2n+2) .gallery-inner .gallery-date h6 {
    background: #f9d464;
}

.galleryCount:nth-child(2n+3) .gallery-inner .gallery-date h6 {
    background: #00acee;
}

.galleryCount:nth-child(2n+4) .gallery-inner .gallery-date h6 {
    background: #198754;
}

.innersec .gallery-block a {
    color: inherit;
}

.gallery-content {
    padding: 20px 15px;
    position: relative;
    background: #fff;
    width: 96%;
    margin: -30px auto 10px auto;
}

.innersec .gallery-block img {
    width: auto;
    max-width: 100%;
    height: 90%;
    margin: auto;
    display: block;
    position: relative;
    box-shadow: 0px 0px 19px 0px #00000054;
    border: 7px solid #ffffff4f;
}

.gallery-inner:hover .gallery-content {
    bottom: 0;
}

.innersec .gallery-block {
    height: 230px;
    object-fit: contain;
    position: relative;
    background: #ddd;
    overflow: hidden;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.sub-gallerycount .innersec .gallery-block img {
    height: 100%;
}

.sub-gallerycount .innersec .gallery-block {
    height: 200px;
    margin-bottom: 20px;
}

.innersec .gallery-block .gallery-title h3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.gallery-title h3 {
    font-size: 18px;
    color: #b78c0b;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    padding: 0px;
    margin-bottom: 15px;
    font-family: 'Poppins', sans-serif;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
    background: transparent;
}

h4.gallery-title {
    color: #000000bf;
    font-size: 20px;
    line-height: 30px;
    font-family: "Inter", sans-serif;
}

.eventbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #f1f1f1bd;
    box-shadow: 2px 0px 8px 0px #ccc;
    margin-bottom: 20px;
}

.eventboximg {
    width: 40%;
    margin-right: 5px;
    position: relative;
}

.eventboxdesc {
    width: 58%;
}

.eventpgh {
    height: 125px;
    overflow-y: scroll;
    padding-top: 13px;
}

.eventboximg img {
    border: 10px solid #f1f1f1bd;
    height: 165px;
    object-fit: contain;
    background: #fff;
    width: 100%;
}

.eventdate {
    display: flex;
    justify-content: space-between;
    padding: 8px 10px 8px 5px;
}

.eventpgh p {
    font-size: 15px;
    line-height: 30px;
}

.eventdate a {
    border-radius: 5px 3px 6px 0px;
    color: #fff;
    padding: 2px 5px;
}

.eventdate span {
    font-size: 13px;
    line-height: 23px;
    color: #1c1b3b;
}

.eventpgh h5 {
    color: #9e790c;
    line-height: 30px;
    font-size: 18px;
    font-family: "Inter", sans-serif;
}

.eventdate span i {
    color: #1c1b3b;
    position: relative;
    bottom: 3px;
}

.eventpgh::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.eventpgh::-webkit-scrollbar-thumb {}

.eventpgh::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: #F5F5F5;
}

.navbar .nav-item .dropdown-menu li,
.navbar .nav-item .dropdown-menu li:last-child a {
    border-bottom: 0px solid #fff;
}

.backDiv {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    filter: blur(3px);
    background-size: 100% 100% !important;
}

.eventattch {
    background: #1c1b3b;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0px 5px 4px 5px;
    border-radius: 0px 0px 10px 10px;
    color: #fff;
}

.dropdown-item.active {
    background: #ba791a;
}

.aboutdesc {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
    overflow: hidden;
}

.mobilefotter {
    display: none;
}

.news-blk .content h3,
.event-blk .content h3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    font-size: 20px;
}

.mobileonline .fix-list a {
    color: #fff;
}

.topperssec {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.topperdiv {
    margin: 8px 10px 10px 10px;
    width: calc(20% - 20px);
    background: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    position: relative;
}

.topperdivimg {
    width: 115px;
    height: 115px;
    margin: 20px auto;
}

.topperdivimg img {
    width: 100%;
    height: 100%;
    border-radius: 130px;
    border: 2px solid #02749e;
    background: #fff;
    object-fit: contain;
}

.topperdivdesc {
    background: #e8f9ff;
    padding: 10px 5px;
}

.topperdivdesc p {
    margin-bottom: 0;
    text-align: center;
    font-size: 16px;
}

.topperdivdesc p span {
    display: block;
    font-size: 15px;
}

.topperper {
    position: absolute;
    top: -2px;
    background: #a7662c;
    width: max-content;
    border-radius: 2px 2px 10px 10px;
}

.topperper p {
    margin-bottom: 0;
    padding: 5px 10px;
    color: #fff;
}

p.board {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    color: #000000;
    font-family: 'Oleo Script', system-ui;
    position: absolute;
    z-index: 9;
    background: #e3c351;
    padding: 3px;
    border-radius: 10px;
}

p.board i {
    margin-left: 10px;
}

.galtitle span {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 1600px) {

    .acheiver-image,
    ul#birthday .acheiver-blk .acheiver-image {
        margin-right: 6px;
    }

    .acheiver-blk .content h3,
    .news-blk .content h3,
    .event-blk .content {
        font-size: 21px;
    }

    .container-fluid.conductbox {
        padding: 0px 100px;
    }

    ul.list-block li img {
        width: 70px;
    }

    ul.list-block li {
        margin: 0 11px;
        font-size: 20px;
        width: 180px;
    }

    img.kgschool {
        width: 280px;
        right: 10px;
    }

    .about-img:before {
        right: 68px;
    }

    .about-img img {
        width: 65%;
    }

    .menu-sec .navbar li a {
        font-size: 13px;
    }

    .news,
    .events {
        padding: 0 90px 0 20px;
    }
}

@media (max-width: 1400px) {


    .topperdiv {
        width: calc(25% - 20px);
    }

    .videoloader video {
        object-fit: contain;
    }

    .mobile-apps {
        margin-left: 0;
    }

    .q-link {
        margin-right: 8px;
    }

    footer {
        padding: 40px 15px 10px;
    }

    .menu-sec {
        padding: 0px 5px;
    }

    .event-blk .content {
        padding: 10px 14px 5px 14px;
    }

    .news-blk .date {
        padding: 14px 18px;
        margin-top: -10px;
    }

    .acheiver-blk .acheiver-image img {
        width: 100px;
        height: 100px;
    }

    .event-blk .content h3 {
        font-size: 21px;
    }

    .news,
    .events {
        padding: 0 90px 0 20px;
    }

    ul.navbar-nav li.nav-item.dropdown {
        font-size: 13px;
        margin: 0 2px;
    }

    .menu-sec .navbar li a {
        padding-right: 4px !important;
        padding-left: 4px !important;
        font-size: 12px;
    }

    ul.navbar-nav li {
        margin: 0 2px;
    }
}

@media (max-width: 1200px) {
    .para {
        background: #f7f7f7;
        line-height: 58px;
        padding: 47px;
        border-radius: 10px;
    }

    .topperdiv {
        width: calc(33% - 20px);
    }

    .logo-load h1 {
        font-size: 50px;
    }

    .link_Button img {
        margin: 10px;
        width: 140px;
    }

    .mobilefotter .mobile-apps {
        display: block;
        text-align: center;
        margin: 0 auto;
    }

    .desktopfooter .mobile-apps {
        display: none;
    }

    .mobilefotter {
        display: block;
    }

    .erppopup {
        width: 45%;
    }

    .examresultbox {
        width: calc(33% - 15px);
    }

    .container-fluid.conductbox {
        padding: 0px 70px;
    }

    .news-event {
        background: linear-gradient(90deg, #e3c351 58%, #dbdbdb 40%);
    }

    .news,
    .events {
        padding: 0 0px 0 0px;
    }

    .gallery-blk .gallery-img {
        width: calc(50% - 20px);
    }

    .message-content {
        padding: 27px 26px;
    }

    .message-content:before {
        display: none;
    }

    .about {
        padding: 0 20px;
    }

    li.nav-item.fix-list {
        position: fixed;
        right: 0;
        transform: translate(5%, -50%);
        bottom: 57%;
        z-index: 99;
    }

    header .d-flex.space-between {
        justify-content: end;
    }

    footer .header-contact {
        margin-bottom: 30px;
        padding: 0 2px;
    }

    header .header-contact {
        display: none;
    }
}

@media (max-width: 991px) {
    .topperdiv {
        width: calc(50% - 20px);
    }

    .logo-load img {
        width: 210px;
    }

    .logo-load h1 {
        font-size: 40px;
    }

    .nav-fill .nav-item,
    .nav-fill>.nav-link {
        flex: unset;
        text-align: left;
    }

    li.nav-item {
        z-index: 999;
        position: relative;
        background: #1c1b3b;
        width: 100%;
        text-align: left;
    }

    .menu-sec .navbar-collapse ul {
        text-align: left;
    }

    .menu-sec ul li i {
        text-align: center;
    }

    .link_Button img {
        width: auto;
    }

    .desktopfooter .mobile-apps {
        display: flex;
    }

    .mobilefotter .mobile-apps {
        display: none;
    }

    .gallery-blk .gallery-img:hover .title {
        font-size: 17px;
    }

    .gallery-blk .gallery-img .title {
        font-size: 17px;
    }

    ul.list-block {
        flex-wrap: wrap;
    }

    .examresultbox {
        width: calc(50% - 15px);
    }

    .contactusdiv {
        width: calc(53% - 30px);
    }

    .contactusbox {
        justify-content: center;
    }

    .container-fluid.conductbox {
        padding: 0px 20px;
    }

    .msgbox {
        width: 50%;
    }

    .teacherimg {
        width: 50%;
    }

    .news-event {
        background: linear-gradient(180deg, #e3c351 50%, #dbdbdb 40%);
        padding: 50px 0px;
    }

    ul.list-block li {
        margin: 0 11px 22px;
        width: calc(30% - 22px);
    }

    .arrow {
        display: none;
    }

    .about-img {
        margin-bottom: 100px;
    }

    header .fix-list {
        display: none;
    }

    .mobileonline {
        width: 100%;
        position: relative;
    }

    .mobileonline .fix-list {
        display: block;
        position: fixed;
        top: 40%;
        transform: translate(-50%, -50%);
        right: -110px;
        z-index: 999;
        width: max-content;
    }

    button.navbar-toggler:focus {
        outline: none;
        box-shadow: none;
    }

    ul.navbar-nav li a {
        margin: 5px 2px;
    }

    .menu-sec .navbar-collapse ul {
        flex-direction: column;
        align-items: flex-start;
    }

    button.navbar-toggler {
        display: block;
        margin-left: auto;
        padding: 0;
        font-size: 24px;
        padding-right: 0;
        margin-top: 0;
    }

    .pp-login img {
        width: 60px;
    }

    .menu-sec {
        padding: 0px 2px;
    }
}

@media (max-width: 767px) {
    .logo-load img {
        width: 150px;
    }

    .logo-load h1 {
        font-size: 25px;
    }

    .about {
        margin: 60px 0;
    }

    .gallery-blk .gallery-img:hover .title {
        font-size: 15px;
    }

    .gallery-blk .gallery-img .title {
        font-size: 15px;
    }

    .popup .modal-dialog {
        min-width: 98%;
        width: 98%;
    }

    .motoboxfst {
        margin: 10px 12px 10px 0px;
        width: 100%;
    }

    .tcblock iframe {
        width: 100%;
    }

    .tc {
        width: 100%;
    }

    .erpboximg {
        width: 100%;
    }

    .erpboxdesc {
        width: 100%;
    }

    .innersec h1 {
        font-size: 30px;
        line-height: 40px;
        padding-bottom: 5px;
    }

    .innersec {
        padding: 60px 10px;
    }

    .examresultbox {
        width: 100%;
        margin: 10px 5px 6px 5px;
    }

    .contactusdiv {
        width: 100%;
        padding: 20px 15px;
    }

    .msgbox {
        margin-top: 20px;
        width: 100%;
        float: none;
        margin-left: 0px;
    }

    .termbox {
        width: 48%;
        margin: 5px 5px;
    }

    .teacherimg {
        width: 100%;
        float: none;
        margin-left: 0;
    }

    ul.list-block {
        flex-wrap: wrap;
        margin-top: 0;
    }

    .shuffleDiv {
        margin-top: 20px;
    }

    ul.list-block {
        margin: 40px 0 0;
    }
}

@media (max-width: 600px) {

    .news,
    .events {
        margin: 0px 0 30px;
    }

    .gallery-blk .gallery-img {
        width: calc(100% - 20px);
    }

    ul.list-block li {
        width: calc(50% - 22px);
        padding: 5px 0;
    }

    .message {
        padding: 15px 0;
        background: #fff;
    }

    .message:before {
        background: #fff;
    }

    footer {
        padding: 40px 10px 10px;
    }

    footer .d-flex {
        flex-direction: column;
    }
}

@media (max-width: 567px) {
    .topperdiv {
        width: 100%;
    }

    .innerslide {
        height: 165px;
        padding-left: 40px;
    }

    .innersec {
        padding: 40px 10px;
    }

    .innersec h1 {
        font-size: 24px;
        line-height: 35px;
    }

    .erppopup {
        width: 100%;
    }

    .termbox {
        width: 100%;
    }
}

@media (max-width: 420px) {
    .alumni-register p {
        font-size: 16px;
    }

    .alumni-register img {
        width: 42px;
    }
}